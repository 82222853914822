import * as React from "react";
import Layout from "../components/layout";
import { useIntl } from "gatsby-plugin-react-intl";
import { StaticQuery, graphql } from "gatsby";
import ArticleTile from "../components/article-tile";
import { getImage } from "gatsby-plugin-image";

const ProjectsPage = () => {
  const intl = useIntl();
  return (
    <>
      <StaticQuery
        query={graphql`
          query Projects {
            allWpPage {
              edges {
                node {
                  modified
                  title
                  slug
                  content
                  page {
                    pageGroup
                    language
                    shortDescription
                    projectGroup {
                      ... on WpProjectsGroups {
                        id
                      }
                    }
                  }
                  featuredImage {
                    node {
                      localFile {
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                    }
                  }
                }
              }
            }
            allWpProjectsGroups {
              edges {
                node {
                  title
                  id
                }
              }
            }
          }
        `}
        render={(data) => {
          const pages = data.allWpPage.edges;

          const projectsGroups = data.allWpProjectsGroups.edges;

          const projects = pages.filter(
            (i) =>
              i.node.page.pageGroup === "projects" &&
              i.node.page.language === intl.locale
          );


          return (
            <Layout>
              <main className="overflow-hidden">
                <header className="px-4 py-6 sm:pt-[7.5rem]">
                  <h1 className="big-heading">
                    {intl.formatMessage({ id: "OurProjects" })}
                  </h1>
                </header>
                <div className="pb-16 space-y-8 divide-y divide-utdf-secondary">
                  {projectsGroups.map((group, i) => (
                    <section
                      key={i}
                      className="hidden has-[article]:block max-w-7xl mx-auto px-8 pt-8"
                    >
                      <h2 className="mb-6 text-3xl font-gambetta text-utdf-secondary">
                        {group.node.title}
                      </h2>
                      <div className="grid items-start grid-cols-1 gap-8 mx-auto md:grid-cols-2 lg:grid-cols-3">
                        {projects
                          .filter((i) => {
                            if (i.node.page?.projectGroup) {
                              return (
                                i.node.page.projectGroup[0]?.id === group.node.id
                              );
                            }
                            return false;
                          })
                          .map((project, i) => (
                            <ArticleTile
                              key={i}
                              link={project.node.slug}
                              title={project.node.title}
                              gatsbyImage={getImage(
                                project.node?.featuredImage?.node?.localFile
                              )}
                              description={project.node.page.shortDescription}
                            />
                          ))}
                      </div>
                    </section>
                  ))}
                </div>
              </main>
            </Layout>
          );
        }}
      ></StaticQuery>
    </>
  );
};

export default ProjectsPage;
